import React from 'react'
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

export default function DateRangePickerPrimary(props) {

	const { defaultValue, disabledDate, placement, paddingLeft } = props;

	const { dateRange, dateRangeChange } = props;
	const [startDate, endDate] = dateRange;

	return (
		<DateRangePicker
			size="lg"
			appearance="subtle"
			placeholder="Select Date Range"
			className="form-control hide-border-control"
			character=" - "
			format='dd/MM/yyyy'
			placement={placement}
			defaultValue={[new Date(startDate), new Date(endDate)]}
			disabledDate={disabledDate}
			startdate={startDate}
			enddate={endDate}
			onChange={(update) => {
				dateRangeChange(update);
			}}
			cleanable={false}
		/>
	)
}

