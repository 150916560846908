import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    getFinancesPayments
} from "../store/financesSummarySlice";

import {
    formatter
} from "../../../../utils/common";

const Payments = (props) => {

    const {
        filters
    } = props;

    const dispatch = useDispatch();

    const financesSummaryState = useSelector(
        ({ report }) => report.financesSummary
    );

    const totalPayments = financesSummaryState.paymentLists.reduce((sum, row) => sum + row.total_amount, 0);

    const [loading, setLoading] = useState(true);

    const [timer, setTimer] = useState(null);
    const [lastFillters, setLastFillters] = useState(null);

    const getFinancesPaymentsData = () => {
        if (JSON.stringify(filters) == JSON.stringify(lastFillters)) return;

        setLastFillters({ ...filters });
        setLoading(true);

        dispatch(
            getFinancesPayments(filters)
        ).then((res) => {
            if (!res.error) {
                setLoading(false);
            }
        });
    };

    useEffect(() => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            getFinancesPaymentsData();
        }, 100);
        setTimer(newTimer)
    }, [dispatch, filters])


    return (
        <div className="card">
            {loading && (
                <div className="card-loading">
                    <i className="fa fa-spinner fa-lg spinner" />
                </div>
            )}

            <div className="card-body">
                <h5 className="card-title">Payments Source</h5>

                {financesSummaryState.paymentLists.length > 0 ? <>
                    {financesSummaryState.paymentLists.map((item, index) => (
                        <div key={index} className="d-flex justify-content-between mb-2">
                            <span className="text-secondary text-decoration-none">{item.name}</span>
                            <span>{formatter.format(item.total_amount)}</span>
                        </div>
                    ))}


                    <div className="d-flex justify-content-between mb-2">
                        <span className="text-primary text-decoration-none font-weight-bold">Total</span>
                        <span className="text-primary font-weight-bold">{formatter.format(totalPayments)}</span>
                    </div>

                </> : <>
                    <p className="text-muted font-italic">No payments data.</p>
                </>}

            </div>
        </div>
    );
};

export default Payments;
