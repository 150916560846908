import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import {
    getFinancesSummarySettingApi, 
    getFinancesSummaryApi,
    exportFinancesSummaryApi,
    getFinancesSalesDetailsApi,
    exportFinancesSalesDetailsApi,
    getFinancesDiscountsDetailsApi,
    exportFinancesDiscountsDetailsApi,
    getFinancesReturnsDetailsApi,
    exportFinancesReturnsDetailsApi,
    getFinancesPaymentsApi,
    getFinancesDeliveryPartnersApi,
} from "../../../../services/report";


export const getFinancesSummary = createAsyncThunk('report/getFinancesSummary', async (data, { dispatch }) => {
    const response = await getFinancesSummaryApi(data);
    dispatch(setData(response.response));
    return response.response;
});


export const exportFinancesSummary = createAsyncThunk('report/exportFinancesSummary', async (data, { dispatch }) => {
    const response = await exportFinancesSummaryApi(data);
    return response.response;
});


export const getFinancesSummarySetting = createAsyncThunk('report/getFinancesSummarySetting', async (data, { dispatch, }) => {
    const response = await getFinancesSummarySettingApi(data);
    dispatch(setSettings(response.response))
    return response.response;
});


export const getFinancesSalesDetails = createAsyncThunk('report/getFinancesSalesDetails', async (data, { dispatch }) => {
    const response = await getFinancesSalesDetailsApi(data);
    dispatch(setSaleDetailsData(response.response));
    return response.response;
});


export const exportFinancesSalesDetails = createAsyncThunk('report/exportFinancesSalesDetails', async (data, { dispatch }) => {
    const response = await exportFinancesSalesDetailsApi(data);
    return response.response;
});


export const getFinancesDiscountsDetails = createAsyncThunk('report/getFinancesDiscountsDetails', async (data, { dispatch }) => {
    const response = await getFinancesDiscountsDetailsApi(data);
    dispatch(setDiscountDetailsData(response.response));
    return response.response;
});


export const exportFinancesDiscountsDetails = createAsyncThunk('report/exportFinancesDiscountsDetails', async (data, { dispatch }) => {
    const response = await exportFinancesDiscountsDetailsApi(data);
    return response.response;
});


export const getFinancesReturnsDetails = createAsyncThunk('report/getFinancesReturnsDetails', async (data, { dispatch }) => {
    const response = await getFinancesReturnsDetailsApi(data);
    dispatch(setReturnDetailsData(response.response));
    return response.response;
});


export const exportFinancesReturnsDetails = createAsyncThunk('report/exportFinancesReturnsDetails', async (data, { dispatch }) => {
    const response = await exportFinancesReturnsDetailsApi(data);
    return response.response;
});


export const getFinancesPayments = createAsyncThunk('report/getFinancesPayments', async (data, { dispatch }) => {
    const response = await getFinancesPaymentsApi(data);
    dispatch(setPaymentsData(response.response));
    return response.response;
});


export const getFinancesDeliveryPartners = createAsyncThunk('report/getFinancesDeliveryPartners', async (data, { dispatch }) => {
    const response = await getFinancesDeliveryPartnersApi(data);
    dispatch(setDeliveryPartnersData(response.response));
    return response.response;
});


const financesSummaryAdapter = createEntityAdapter({});

export const { selectAll: selectFinancesSummary, selectById: selectFinancesSummaryById } = financesSummaryAdapter.getSelectors(
    state => state.report
);

const financesSummarySlice = createSlice({
    name: 'financesSummary',
    initialState: financesSummaryAdapter.getInitialState({
        id: null,
        settings: {
            partners: [],
        },
        filters: {
            startDate: null,
            endDate: null,
            partner: null
        },
        data: {
            gross_sales: 0,
            discount: 0,
            shipping_charges: 0,
            total_tax: 0,
            total_return: 0,
            net_sales: 0,
            total_sales: 0,
            total_cost: 0,
            gross_profit: 0,
            final_profit: 0,
            commission_percentage: 0,
            commission_amount: 0,
            additional_commission_percentage: 0,
            additional_commission_amount: 0,
            agent_commission_percentage: 0,
            agent_commission_amount: 0,
            debenham_difference_gross_sales: 0,
            difference_gross_sales: 0,
            debenham_difference_discount: 0,
            difference_discount: 0,
            difference_total_return: 0,
            difference_vat_round_gross_sales: 0,
            taxItems: [],
            partnerItems: [],
            countryItems: {}
        },
        test_order_summary: null,
        deliveryPartnersData: [],
        returnDeliveryPartnersData: [],
        paymentLists: [],
        saleDetailsLists: [],
        saleDetailsTableRow: 0,
        discountDetailsLists: [],
        discountDetailsTableRow: 0,
        returnDetailsLists: [],
        returnDetailsTableRow: 0,
    }),
    reducers: {
        setSettings: (state, action) => {
            state.settings.partners = action.payload.partners;
        },
        setFilter: (state, action) => {
            state.filters = action.payload;
        },
        setData: (state, action) => {
            state.data = action.payload.data;
            state.test_order_summary = action.payload.test_order_summary;
        },
        setPaymentsData: (state, action) => {
            state.paymentLists = action.payload;
        },
        setDeliveryPartnersData: (state, action) => {
            state.deliveryPartnersData = action.payload.delivery_partners_data;
            state.returnDeliveryPartnersData = action.payload.return_delivery_partners_data;
        },
        setSaleDetailsData: (state, action) => {
            state.saleDetailsLists = action.payload.data;
            state.saleDetailsTableRow = action.payload.total;
        },
        setDiscountDetailsData: (state, action) => {
            state.discountDetailsLists = action.payload.data;
            state.discountDetailsTableRow = action.payload.total;
        },
        setReturnDetailsData: (state, action) => {
            state.returnDetailsLists = action.payload.data;
            state.returnDetailsTableRow = action.payload.total;
        },
    },
    extraReducers: {
        // [getFinancesSummary.fulfilled]: financesSummaryAdapter.setAll
    }
});

export const {
    setSettings,
    setFilter,
    setData,
    setPaymentsData,
    setDeliveryPartnersData,
    setSaleDetailsData,
    setDiscountDetailsData,
    setReturnDetailsData,
} = financesSummarySlice.actions;

export default financesSummarySlice.reducer;