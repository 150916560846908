import React from 'react';
import { PopoverBody } from 'reactstrap';

import {
    formatter, formatterDecimal
} from "../../../../utils/common";

import PopoverFormula from './PopoverFormula';

const GrossProfitSummary = (props) => {

    const {
        data,
        loading,
        getPercentage
    } = props;

    return (
        <div className="card">
            {loading && (
                <div className="card-loading">
                    <i className="fa fa-spinner fa-lg spinner" />
                </div>
            )}

            <div className="card-body">
                <h5 className="card-title">Gross profit</h5>
                <div className="d-flex justify-content-between mb-2">
                    <div>
                        <a href="#" className="text-decoration-none">Net sales</a>
                        <PopoverFormula className="ml-3" id="grossProfitNetSalesFormula">
                            <PopoverBody>
                                <h6>Net Sales Formula</h6>
                                <p>Net Sales = Gross Sales - Discounts - Returns</p>
                            </PopoverBody>
                        </PopoverFormula>
                    </div>
                    <span>{formatter.format(data.net_sales)}</span>
                </div>
                <div className="d-flex justify-content-between mb-2">
                    <div>
                        Cost of goods sold
                        <span className='text-dark pl-2'>
                            ({getPercentage(data.net_sales, data.total_cost)}%)
                        </span>
                        <PopoverFormula className="ml-3" id="grossProfitCostFormula">
                            <PopoverBody>
                                <h6>Cost Formula</h6>
                                <p>Cost of Goods Sold = Cost price (excl. VAT) of products in net sales orders</p>
                            </PopoverBody>
                        </PopoverFormula>
                    </div>
                    <span>({formatter.format(data.total_cost)})</span>
                </div>

                <div className="d-flex justify-content-between mb-2">
                    <div>
                        Commission
                        <span className='text-dark pl-2'>
                            ({formatterDecimal.format(data.commission_percentage)}%)
                        </span>
                        <PopoverFormula className="ml-3" id="grossProfitCommissionFormla">
                            <PopoverBody>
                                <h6>Commission Formula</h6>
                                <p>Commission = Commission Percentage * Net Sales / 100</p>
                            </PopoverBody>
                        </PopoverFormula>
                    </div>
                    <span>({formatter.format(data.commission_amount)})</span>
                </div>

                <div className="d-flex justify-content-between mb-2">
                    <div>
                        Additional Charges
                        <span className='text-dark pl-2'>
                            ({formatterDecimal.format(data.additional_commission_percentage)}%)
                        </span>
                        <PopoverFormula className="ml-3" id="grossProfitAdditionalChargesFormla">
                            <PopoverBody>
                                <h6>Additional Charges</h6>
                                <p>Additional Charges = Additional Charges Percentage * Net Sales / 100</p>
                            </PopoverBody>
                        </PopoverFormula>
                    </div>
                    <span>({formatter.format(data.additional_commission_amount)})</span>
                </div>

                <div className="d-flex justify-content-between mb-2">
                    <div>
                        Agent Commission
                        <span className='text-dark pl-2'>
                            ({formatterDecimal.format(data.agent_commission_percentage)}%)
                        </span>
                        <PopoverFormula className="ml-3" id="grossProfitAgentCommissionFormula">
                            <PopoverBody>
                                <h6>Agent Commission Formula</h6>
                                <p>Agent Commission = (Net Sales - Commission) * Agent Commission Percentage / 100</p>
                            </PopoverBody>
                        </PopoverFormula>
                    </div>
                    <span>({formatter.format(data.agent_commission_amount)})</span>
                </div>

                <div className="d-flex justify-content-between mb-2">
                    <div>
                        Shipping
                        <PopoverFormula className="ml-3" id="grossProfitShippingFormla">
                            <PopoverBody>
                                <h6>Shipping Formula</h6>
                                <p>Shipping = Shipping Charge on the order</p>
                            </PopoverBody>
                        </PopoverFormula>
                    </div>
                    <span>{formatter.format(data.shipping_charges)}</span>
                </div>

                <hr />
                <div className="d-flex justify-content-between font-weight-bold mb-2">
                    <div>
                        <a href="#" className="text-decoration-none">
                            {data.gross_profit > 0 ? 'Gross profit' : 'Gross loss'}
                        </a>
                        <span className='text-dark pl-2'>({getPercentage(data.net_sales, data.gross_profit)}%)</span>
                        
                        <PopoverFormula className="ml-3" id="grossProfitFormla">
                            <PopoverBody>
                                <h6>Gross Profit Formula</h6>
                                <p>Gross profit = Net Sales + Shipping - Cost of goods sold - Commission - Additional Charges - Agent Commission</p>
                            </PopoverBody>
                        </PopoverFormula>
                    </div>
                    <span>{formatter.format(data.gross_profit)}</span>
                </div>
                <div className="d-flex justify-content-between font-weight-bold mb-2">
                    <div>                        
                        <a href="#" className="text-decoration-none">
                            Gross Margin
                        </a>

                        <PopoverFormula className="ml-3" id="grossMarginFormla">
                            <PopoverBody>
                                <h6>Gross Margin Formula</h6>
                                <p>Gross Margin = 100 * Gross profit / (Net sales + Shipping)</p>
                            </PopoverBody>
                        </PopoverFormula>
                    </div>
                    <span>{formatterDecimal.format(100 * data.gross_profit / (data.net_sales + data.shipping_charges))}%</span>
                </div>                
            </div>
        </div>
    );
};

export default GrossProfitSummary;
