import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {Button, Col, Form, FormGroup, Input, Label, Nav, NavItem, NavLink, Row} from "reactstrap";
import Select from "react-select";
import {bulkProductPublish, bulkProductSalesOffer, setProductFilter} from "../../../store/prodcutSlice";
import {Target} from "react-feather";
import ButtonLoader from "../../../../../share-components/ui/buttonLoader";
import {useDispatch} from "react-redux";
import { X,Plus} from "react-feather";
// import DatePicker from "../../../../../share-components/ui/DateRangePicker";
import DatePicker from "react-datepicker";
import {DateRangePicker} from "rsuite";
import moment from 'moment';
let momentTZ = require('moment-timezone');

// const {beforeToday} = DateRangePicker;

const defaultValues = {
    tag: [],
    tag_type:"",
    offer_type:1,
    is_add_tag:1,
    max_amount:"",
    is_remove_offer:0,
    value: '',
};
yup.addMethod(yup.mixed, "offerValidation", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        const {path, createError} = this;
        return this.parent.tag.length >0 || this.parent.value || this.parent.is_remove_offer || this.parent.tag_type

    });
});
yup.addMethod(yup.string, "maxAmountValidation", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        const {path, createError} = this;
        if(this.parent.offer_type ===2 ){
             return  value?true:false;
        }
        return  true;

    });
});

yup.addMethod(yup.string, "customValidation", function (errorMessage) {
    return this.test(`test-card-length`, errorMessage, function (value) {
        const {path, createError} = this;
        let isValid = true;
        if(this.parent.is_save_offer != true ){
            return  value?true:false;
        }
        return  true;
    });
});
const schema = yup.object().shape({
    tag: yup.mixed().offerValidation('tag or type or offer value must enter'),
    tag_type:yup.mixed().offerValidation('tag or type or offer value must enter'),
    value: yup.mixed().offerValidation('tag or  type or offer value must enter'),
    max_amount: yup.string().maxAmountValidation('max amount must enter'),
    // name: yup.string().required("Name is required").typeError('Name is required'),
    name: yup.object().when(['is_save_offer'],(is_save_offer)=>{
        if (is_save_offer) {
            return yup.string().required('Name is required')
        }
        return yup.string().nullable()
    }),
    startDate: yup.object().when(['is_save_offer'],(is_save_offer)=>{
        if (is_save_offer) {
            return yup.date().required('Start Date is required')
        }
        return yup.date().nullable()
    }),
    endDate: yup.object().when(['is_save_offer'],(is_save_offer)=>{
        if (is_save_offer) {
            return yup.date().required('End Date is required')
                .min(yup.ref('startDate'), 'End Date must be after Start Date')
        }
        return yup.date().nullable()
    }),
});



function SaleOffer(props) {
    const {state,closeDialog,selectedProductId,setSelectedProductId,productFilter,selectAllRef,setSelectedPage} = props;
    const dispatch = useDispatch();
    const [buttonDisable,setButtonDisable]=useState(false);
    const {handleSubmit, formState, reset, control, register, setValue, trigger, getValues} = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(schema)
    });
    const TodayDate = new Date();
    const sevenDays = new Date().setDate(TodayDate.getDate() - 90);
    const styles = { minHeight: '50px !important', display: 'block', padding: '0 !important'};
    const {errors} = formState
    const onSubmit = (data) => {

        data.product_ids=selectedProductId;
        data.tag_type=data.tag_type?.id;
        data.is_offer = data.is_save_offer;
        data.title = data.name;
        data.end_date = data.endDate ? moment(data.endDate).format('YYYY-MM-DD HH:mm:ss') : null;
        data.start_date = data.startDate ? moment(data.startDate).format('YYYY-MM-DD HH:mm:ss') : null;
        data.tag_id=data.tag.map(tag => tag.id);
        setButtonDisable(true);
        dispatch(bulkProductSalesOffer({...data})).then(res => {
            productFilter();
            setSelectedProductId([]);
            setSelectedPage([]);
            setButtonDisable(false);
            closeDialog();
        });

    }

    /*const filterTime = (date) => {
        const isPastTime = new Date().getTime() > date.getTime();
        return !isPastTime;
    };*/
    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col sm={12}>
                        <FormGroup>
                            <Row>
                                <Col sm={12}>
                                    <div className="checkbox checkbox-dark ml-3">
                                        <Input id="is_save_offer" type="checkbox" onChange={(e) => {
                                            setValue('is_save_offer', e.target.checked)
                                            trigger('is_save_offer');
                                        }}/>
                                        <Label for="is_save_offer">Create as Sale Offer</Label>
                                    </div>
                                </Col>
                            </Row>
                            {getValues('is_save_offer') && <Row>
                                <Col sm={12}>
                                    <Label className="col-form-label">Sale Offer Name</Label>
                                    <input name="name" type="text" {...register('name')} className={`form-control ${errors.name ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.name?.message}</div>
                                </Col>
                            {/* </Row>
                            <Row> */}
                                <Col sm={12}>
                                    <Label className="col-form-label">Start Date</Label>

                                        <DatePicker name="startDate"
                                                className={`form-control digits ${errors.startDate ? 'is-invalid' : ''}`}
                                                selected={getValues('startDate') ? new Date(getValues('startDate')) : null}
                                                onChange={(date) => {
                                                    setValue('startDate', date);
                                                    trigger('startDate')
                                                }}
                                                showTimeSelect={true}
                                                timeIntervals={1}
                                                //filterTime={filterTime}
                                                minDate={moment().toDate()}
                                                style={{minHeight: 'auto'}}
                                                dateFormat="dd-MM-yyyy h:mm aa"
                                                autoComplete="off" />
                                        <div className="invalid-feedback" style={{display:"block"}}>{errors.startDate?.message}</div>

                                </Col>

                                <Col sm={12}>
                                    <Label className="col-form-label">End Date</Label>

                                        <DatePicker name="endDate"
                                                className={`form-control digits ${errors.endDate ? 'is-invalid' : ''}`}
                                                selected={getValues('endDate') ? new Date(getValues('endDate')) : null}
                                                onChange={(date) => {
                                                    setValue('endDate', date);
                                                    trigger('endDate')
                                                }}
                                                showTimeSelect={true}
                                                timeIntervals={1}
                                                minDate={moment().toDate()}
                                                //filterTime={filterTime}
                                                style={{minHeight: 'auto'}}
                                                dateFormat="dd-MM-yyyy h:mm aa"
                                                autoComplete="off" />
                                        <div className="invalid-feedback" style={{display:"block"}}>{errors.endDate?.message}</div>
                                </Col>
                            </Row>}
                        </FormGroup>
                        <hr/>
                        <FormGroup>
                            <Label className="col-form-label">Tag Type</Label>
                            <Row>
                                <Col md={12}>

                                    <Select getOptionLabel={(option) => option.name}
                                            menuPortalTarget={document.body}
                                            styles={{menuPortal: base => ({...base, zIndex: 100000})}}
                                            getOptionValue={(option) => option.id}
                                            onChange={(e) => {
                                                setValue('tag_type',e);
                                                trigger(['tag_type','value']);

                                            }}
                                            isClearable={true}
                                            placeholder="select tags"
                                        // styles={customStyles}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            className={`select2-filter  ${errors.tag_type ? 'is-invalid' : ''}`}
                                            options={state.productIndexSettings.tag_settings}/>
                                    <div className="invalid-feedback">{errors.tag_type?.message}</div>

                                </Col>
                            </Row>

                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>

                        <FormGroup>
                            <Label className="col-form-label">Tag</Label>
                            <Row>
                                <Col sm={4}>
                          <Nav tabs className="border-tab">
                            <NavItem><
                                NavLink
                                className={getValues('is_add_tag') === 1 ? "active" : ""}
                                title="ADD tag"
                                onClick={() => {
                                    setValue('is_add_tag',1);
                                    trigger('is_add_tag');
                                }}> <Plus></Plus></NavLink>
                            </NavItem>
                            <NavItem><
                                NavLink
                                title="Remove tag"
                                className={getValues('is_add_tag') === 0 ? "active" : ""}
                                onClick={() => {
                                    setValue('is_add_tag',0);
                                    trigger('is_add_tag');
                                }}><X></X></NavLink>
                            </NavItem>
                        </Nav>
                                </Col>
                                <Col md={8}>

                                    <Select getOptionLabel={(option) => option.name}
                                            isMulti={true}
                                            menuPortalTarget={document.body}
                                            styles={{menuPortal: base => ({...base, zIndex: 100000})}}
                                            getOptionValue={(option) => option.id}
                                            onChange={(e) => {
                                                setValue('tag',e);
                                                trigger(['tag','value']);

                                            }}
                                            isClearable={true}
                                            placeholder="select tags"
                                        // styles={customStyles}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            className={`select2-filter  ${errors.tag ? 'is-invalid' : ''}`}
                                            options={state.productIndexSettings.tags}/>
                                    <div className="invalid-feedback">{errors.tag?.message}</div>

                                </Col>
                            </Row>

                </FormGroup>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col sm={12}>

                        <FormGroup>
                            <Label className="col-form-label">Sales Offer</Label>
                            <Row>
                                <Col sm={12}>
                                    <div className="checkbox checkbox-dark ml-3">
                                        <Input id="is_remove_offer" type="checkbox" onChange={(e) => {
                                            setValue('is_remove_offer', e.target.checked)
                                            trigger(['is_remove_offer','tag']);
                                        }}/>
                                        <Label for="is_remove_offer">Remove Sales Offer</Label>
                                    </div>
                                </Col>
                            </Row>
                            {!getValues('is_remove_offer') &&<Row>
                                <Col sm={12}>
                                <Nav tabs className="border-tab">
                                    <NavItem><
                                        NavLink
                                        className={getValues('offer_type') === 1 ? "active" : ""}
                                        onClick={() => {
                                            setValue('offer_type',1);
                                            trigger('offer_type');
                                        }}> Percentage</NavLink>
                                    </NavItem>
                                    <NavItem><
                                        NavLink
                                        className={getValues('offer_type') === 2 ? "active" : ""}
                                        onClick={() => {
                                            setValue('offer_type',2);
                                            trigger('offer_type');
                                        }}>Amount</NavLink>
                                    </NavItem>
                                    <NavItem><
                                        NavLink
                                        className={getValues('offer_type') === 3 ? "active" : ""}
                                        onClick={() => {
                                            setValue('offer_type',3);
                                            trigger('offer_type');
                                        }}>Fixed Amount</NavLink>
                                    </NavItem>
                                </Nav>
                                </Col>

                            </Row>}


                        </FormGroup>
                    </Col>
                </Row>
                {!getValues('is_remove_offer') && <Row style={{marginTop:-25}}>
                    {getValues('offer_type')===2 && <Col  md={{ size: 6, offset: 0 }}>
                        <FormGroup>
                            <Label className="col-form-label">Max Amount</Label>
                            <input name="code" type="number" {...register('max_amount')}
                                   className={`form-control ${errors.max_amount? 'is-invalid' : ''}`}/>
                            <div className="invalid-feedback">{errors.max_amount?.message}</div>
                        </FormGroup>
                    </Col>}

                    <Col  md={{ size: 6, offset: getValues('offer_type')===2?0:3}}>
                        <FormGroup>
                        <Label className="col-form-label">Value</Label>
                            <input name="code" type="number"
                                  onChange={(e)=>{
                                      setValue('value',e.target.value)
                                      trigger(['value','tag']);
                                  }}
                                   className={`form-control ${errors.value? 'is-invalid' : ''}`}/>
                            <div className="invalid-feedback">{errors.value?.message}</div>
                        </FormGroup>
                    </Col>
                </Row>}
                <Row>
                    <Col sm={12}>
                        <div className="float-right">
                        <Button className="ml-3" color="secondary"  onClick={() => {

                            closeDialog()
                        }}>Cancel</Button>
                        <ButtonLoader  style={{marginLeft: 20, width:"auto"}} disabled={buttonDisable} color="primary" value="1"
                                      type="submit"
                                      btntext={"save"}></ButtonLoader>
                        </div>
                    </Col>
                </Row>
            </Form>
        </>
)
}

export default SaleOffer;