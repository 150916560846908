import React from 'react';
import { PopoverBody } from 'reactstrap';
import { Link } from "react-router-dom";

import {
    formatter, formatterDecimal
} from "../../../../utils/common";

import PopoverFormula from './PopoverFormula';

const SalesSummary = (props) => {

    const {
        data,
        loading,
        getPercentage,
        generateLink,
        dateWiseReturn
    } = props;

    return (
        <div className="card">
            {loading && (
                <div className="card-loading">
                    <i className="fa fa-spinner fa-lg spinner" />
                </div>
            )}

            <div className="card-body">
                <h5 className="card-title">Sales</h5>
                <div className="d-flex justify-content-between mb-2">
                    <div>
                        <Link to={generateLink('gross-sales')} target="_blank" className="text-decoration-none text-primary">
                            Gross Sales
                        </Link>

                        <PopoverFormula className="ml-3" id="grossSalesFormula">
                            <PopoverBody>
                                <h6>Gross Sales Formula</h6>
                                <p>Gross Sales = UK (Full Price before discount - VAT) Others (Full Price before discount)</p>
                            </PopoverBody>
                        </PopoverFormula>
                    </div>
                    <span>{formatter.format(data.gross_sales)}</span>
                </div>
                <div className="d-flex justify-content-between mb-2">
                    <div>
                        <Link to={generateLink('discounts')} target="_blank" className="text-decoration-none text-primary">
                            Discounts
                            <span className='text-dark pl-2'>({getPercentage(data.gross_sales, data.discount)}%)</span>
                        </Link>

                        <PopoverFormula className="ml-3" id="discountsFormula">
                            <PopoverBody>
                                <h6>Discounts Formula</h6>
                                <p>Discounts = Line Discount, Order Discount, Combination of both</p>
                            </PopoverBody>
                        </PopoverFormula>
                    </div>
                    <span>({formatter.format(data.discount)})</span>
                </div>
                <div className="d-flex justify-content-between mb-2">
                    <div>
                        <Link to={generateLink('returns')} target="_blank" className="text-decoration-none text-primary">
                            Returns
                            <span className='text-dark pl-2'>({getPercentage(data.gross_sales, data.total_return)}%)</span>
                        </Link>

                        <PopoverFormula className="ml-3" id="returnFormula">
                            <PopoverBody>
                                <h6>Returns Formula</h6>
                                <p>Returns {dateWiseReturn ? '' : '(Order Basis)'} = Items which are refunded, void and cancelled</p>
                            </PopoverBody>
                        </PopoverFormula>
                    </div>
                    <span>({formatter.format(data.total_return)})</span>
                </div>
                <hr />
                <div className="d-flex justify-content-between mb-2">
                    <div>
                        Net Sales
                        <PopoverFormula className="ml-3" id="netSalesFormla">
                            <PopoverBody>
                                <h6>Net Sales Formula</h6>
                                <p>Net Sales = Gross Sales - Discounts - Returns</p>
                            </PopoverBody>
                        </PopoverFormula>
                    </div>
                    <span>{formatter.format(data.net_sales)}</span>
                </div>
                <div className="d-flex justify-content-between mb-2">
                    <div>
                        Shipping
                        <PopoverFormula className="ml-3" id="shippingFormla">
                            <PopoverBody>
                                <h6>Shipping Formula</h6>
                                <p>Shipping = Shipping Charge on the order</p>
                            </PopoverBody>
                        </PopoverFormula>
                    </div>
                    <span>{formatter.format(data.shipping_charges)}</span>
                </div>
                <div className="d-flex justify-content-between mb-2">
                    <div>
                        Taxes
                        <span className='text-dark pl-2'>({getPercentage(data.net_sales, data.total_tax)}%)</span>
                        <PopoverFormula className="ml-3" id="taxesFormula">
                            <PopoverBody>
                                <h6>Taxes</h6>
                                <p>
                                    UK - 20% <br />
                                    Ireland - 23% <br />
                                    Others - No VAT
                                </p>
                            </PopoverBody>
                        </PopoverFormula>
                    </div>
                    <span>{formatter.format(data.total_tax)}</span>
                </div>
                <hr />
                <div className="d-flex justify-content-between font-weight-bold">
                    <div>
                        Total Sales

                        <PopoverFormula className="ml-3" id="totalSalesFormla">
                            <PopoverBody>
                                <h6>Total Sales Formula</h6>
                                <p>Total Sales = Net Sales + Shipping + Taxes</p>
                            </PopoverBody>
                        </PopoverFormula>
                    </div>
                    <span>{formatter.format(data.total_sales)}</span>
                </div>
            </div>
        </div>
    );
};

export default SalesSummary;